.queries {
  section {
    padding: 91px 24px 74px 24px;
  }
  h2 {
    width: 274px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: -0.72px;
    color: #212b36;
  }
  .queries-content {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 130px;

    .card-custom {
      &:first-of-type {
        margin-left: 0;
      }
      height: 90px;
      border: none;
      display: flex;
      margin-left: 10px;
      min-width: 220px;
      padding: 21px 24px;
      border-radius: 5px;
      align-items: center;
      background-color: #f4f6f8;
      max-height: 90px;
      h5 {
        margin: 0;
        font-size: 14px;
        color: #212b36;
        font-weight: 600;
        line-height: 1.71;
      }
      p {
        margin: 0;
        width: 130px;
        font-size: 12px;
        color: #637381;
        overflow: hidden;
        line-height: 1.67;
        white-space: nowrap;
        overflow-wrap: normal;
        text-overflow: ellipsis;
      }
      p.small {
        font-size: 10px;
        line-height: 2;
        color: #919eab;
      }
      &-body {
        display: flex;
      }
      img {
        margin-right: 19px;
      }
    }
  }
  @media (max-width: 992px) {
    section {
      padding: 40px 0 20px 0;
    }
    h2 {
      width: 280px;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
      margin: 0 auto 26px;
    }
    h3 {
      margin: 0 auto 25px;
      text-align: center;
    }
    h5 {
      line-height: 24px!important;
    }
    .card-custom p {
      line-height: 20px!important;
    }
  }
}
