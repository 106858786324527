.banner {
  width: 100vw;
  min-height: 320px;
  overflow: hidden;
  position: relative;
  background-color: #f4f6f8;
  background-image: url('/static/images/bannerCliqueFarma/bannerDeskAgConsulta.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 20px 0 40px 0;
}
@media (min-width: 1200px) {
  .banner {
    margin-top: 24px;
    background-size: contain;
  }
}
@media (max-width: 1050px) {
  .banner {
    background-size: contain;
  }
}
@media (max-width: 992px) {
  .banner {
    padding-bottom: 86px;
  }
}
@media (max-width: 600px) {
  .banner {
    background-size: cover;
    background-position-y: 0;
    background-image: url('/static/images/bannerCliqueFarma/bannerMobileAgConsulta.webp');
  }
}
