.info {
  display: flex;
  justify-content: center;
  section {
    .row:first-child {
      margin-bottom: 30px;
    }
    .info-content {
      display: flex;
      max-width: 300px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 5px 31px;
    }
    .card-custom {
      width: 90px;
      height: 90px;
      border: none;
      display: flex;
      margin: 30px 0;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      background-color: #f4f6f8;
    }
    .center {
      justify-content: center;
      display: flex;
    }
    h3 {
      max-width: 220px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      line-height: normal;
      font-stretch: normal;
      color: #212b36;
      margin-bottom: 12px;
      letter-spacing: -0.36px;
    }
    p {
      max-width: 300px;
      font-size: 14px;
      line-height: 1.43;
      color: #637381;
      letter-spacing: -0.32px;
    }
    button {
      height: 36px;
      font-size: 14px;
      margin-top: 25px;
      padding: 0px 19px;
      line-height: 19px;
      border-radius: 3px;
      font-weight: 600;
      background: transparent !important;
      font-family: 'Open Sans', sans-serif;
      border: 2px solid #f4a629 !important;
      color: #f4a629 !important;
    }
  }
  @media (max-width: 992px) {
    section {
      .info-content {
        &:first-of-type {
          margin-top: 0;
        }
        margin: 5px auto;
      }
    }
  }
}
.alignCards {
  justify-content: center;
}
